import React from "react";
import { Container  } from '@chakra-ui/react';

import Navbar from "../components/Molecules/Navbar";

import HeroWithImage from '../components/Organism/HeroWithImage'
import FeatureHowSteps from '../components/Organism/FeatureHowSteps'
import FeatureHowWorks from '../components/Organism/FeatureHowWorks'
import FeatureCTA from '../components/Organism/FeatureCTA'
// import FeaturePricing from '../components/Organism/FeaturePricing'
import SmallFooter from '../components/Organism/SmallFooter'


function FeatureHow() {
  return Math.random() < 0.5 ? <FeatureHowSteps /> : <FeatureHowWorks />
}


export default function Home() {

  return (
    <Container maxW="8xl">
      <Navbar />
      <HeroWithImage />
      <FeatureHow />
      <FeatureCTA />
      {/* <FeaturePricing /> */}
      <SmallFooter />
    </Container>
  );
}