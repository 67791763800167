import * as React from 'react';

import {
  chakra,
  Button,
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  IoBusinessSharp,
  IoBody,
  IoChatbubblesSharp,
} from 'react-icons/io5';
import { MdBolt } from 'react-icons/md';

function Feature({ text, icon, iconBg }) {
  return (
    <Stack direction="row" align="center">
      <Flex
        w={8}
        h={8}
        align="center"
        justify="center"
        rounded="full"
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
}

export default function FeatureHowSteps() {
  return (
    <Container maxW="5xl" py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform="uppercase"
            color="blue.400"
            fontWeight={600}
            fontSize="sm"
            bg={useColorModeValue('blue.50', 'blue.900')}
            p={2}
            alignSelf="flex-start"
            rounded="md">
            Genera mas leads
          </Text>
          <Heading>¿Como puedo generar mas leads con inteligencia artificial?</Heading>
          <Text color="gray.500" fontSize="lg">
          Con nuestro servicio, podrás aprovechar la inteligencia artificial para generar titulos y descripciones únicos para cada propiedad y público objetivo, aumentando tus posibilidades de atraer clientes potenciales.
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>
            <Feature
              icon={
                <Icon as={IoBusinessSharp} color="yellow.500" w={5} h={5} />
              }
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text="Ingresa los datos relevantes de tu inmueble"
            />
            <Feature
              icon={<Icon as={IoBody} color="green.500" w={5} h={5} />}
              iconBg={useColorModeValue('green.100', 'green.900')}
              text="Selecciona uno o mas publicos objetivo"
            />
            <Feature
              icon={
                <Icon as={IoChatbubblesSharp} color="purple.500" w={5} h={5} />
              }
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text="Inmo Copy te generará un título, descripción corta para publicaciones en redes sociales y una descripcion mas amplia para el detalle de tu propiedad"
            />
          </Stack>
          <Stack>
          <Button
            as="a"
              w={{ base: '100%', sm: 'auto' }}
              h={12}
              px={6}
              color="white"
              size="lg"
              rounded="md"
              mb={{ base: 2, sm: 0 }}
              zIndex={5}
              lineHeight={1}
              bgGradient="linear(to-l, #ED64A6,#B83280)"
              _hover={{ bgGradient: 'linear(to-l, #ED64A6,#B83280)', opacity: 0.9 }}
              href="signup"
            >
              <chakra.span> ¡Generar mas leads ahora! </chakra.span>
              <Icon as={MdBolt} h={4} w={4} ml={1} />
            </Button>
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded="md"
            alt="feature image"
            src="https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            objectFit="cover"
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}