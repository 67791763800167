import * as React from 'react';

import { Button, chakra, Container, Stack, HStack, VStack, Flex, Text, Image, Icon, Box } from '@chakra-ui/react';
import { MdBolt } from 'react-icons/md';

const overviewList = [
  { id: 1, label: 'Ingresa los datos relevantes de tu inmueble', subLabel: '' },
  {
    id: 2,
    label: 'Selecciona uno o mas publicos objetivo',
    subLabel: ''
  },
  {
    id: 3,
    label: 'Inmo Copy te generará un título y descripciones',
    subLabel: 'un  descripción corta para publicaciones en redes sociales y una descripcion mas amplia para el detalle de tu propiedad'
  },
];

function FeatureHowWorks() {
  return (
    <Container maxW="6xl" py={10}>
      <chakra.h2 fontSize="4xl" fontWeight="bold" textAlign="center" mb={2}>
        ¿Como puedo generar mas leads con inteligencia artificial?
      </chakra.h2>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 0, md: 3 }}
        justifyContent="center"
        alignItems="center"
      >
        <VStack spacing={4} alignItems="flex-start" mb={{ base: 5, md: 0 }} maxW="md">
          <Text>Con nuestro servicio, podrás aprovechar la inteligencia artificial para generar titulos y descripciones únicos para cada propiedad y público objetivo, aumentando tus posibilidades de atraer clientes potenciales.</Text>

          {overviewList.map((data) => (
            <Box key={data.id}>
              <HStack spacing={2}>
                <Flex
                  fontWeight="bold"
                  boxShadow="md"
                  color="white"
                  bg="blue.400"
                  rounded="full"
                  justifyContent="center"
                  alignItems="center"
                  w={10}
                  h={10}
                >
                  {data.id}
                </Flex>
                <Text fontSize="xl">{data.label}</Text>
              </HStack>
              <Text fontSize="md" color="gray.500" ml={12}>
                {data.subLabel}
              </Text>
            </Box>
          ))}
          <Button
            as="a"
            alignSelf="center"
            w={{ base: '100%', sm: 'auto' }}
            h={12}
            px={6}
            color="white"
            size="lg"
            rounded="md"
            mb={{ base: 2, sm: 0 }}
            zIndex={5}
            lineHeight={1}
            bgGradient="linear(to-l, #ED64A6,#B83280)"
            _hover={{ bgGradient: 'linear(to-l, #ED64A6,#B83280)', opacity: 0.9 }}
            href="signup"
          >
            <chakra.span> ¡Generar mas leads ahora! </chakra.span>
            <Icon as={MdBolt} h={4} w={4} ml={1} />
          </Button>
        </VStack>
        <Image
        alt="Mas leads"
          boxSize={{ base: 'auto', md: 'lg' }}
          objectFit="contain"
          src="https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
          rounded="lg"
        />
      </Stack>
    </Container>
  );
}

export default FeatureHowWorks;