import * as React from 'react';

import { 
  Box, Stack, HStack, Link, 
 Container, useColorModeValue
 } from '@chakra-ui/react';
// Here we have used react-icons package for the icons
// import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';

// const links = ['Login', 'Documentation', 'Careers', 'Sign up', 'Terms of use', 'Privacy policy'];
// const accounts = [
//   {
//     url: 'https://github.com/MA-Ahmad/templateskart',
//     label: 'Github Account',
//     type: 'gray',
//     icon: <FaGithub />
//   },
//   {
//     url: 'https://twitter.com/muhammad_ahmaad',
//     label: 'Twitter Account',
//     type: 'twitter',
//     icon: <FaTwitter />
//   },
//   {
//     url: 'https://linkedin.com/in/muhammad-ahmad20',
//     label: 'LinkedIn Account',
//     type: 'linkedin',
//     icon: <FaLinkedin />
//   }
// ];

function SmallFooter() {
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.900')}
    color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW="6xl" py={10}>
      <Stack
      maxW="5xl"
      marginInline="auto"
      
      spacing={{ base: 8, md: 0 }}
      justifyContent="space-between"
      alignItems="center"
      direction={{ base: 'column', md: 'row' }}
    >
      {/* <Link href="https://templateskart.com" isExternal>
        <Image w="100px" src="/assets/images/layouts/hero_image.png" alt="TemplatesKart" />
      </Link> */}

      {/* Desktop Screen */}
      {/* <HStack spacing={4} alignItems="center" d={{ base: 'none', md: 'flex' }}>
        {links.map((link, index) => (
          <CustomLink key={index}>{link}</CustomLink>
        ))}
      </HStack> */}
      {/* <HStack spacing={4} alignItems="center" d={{ base: 'none', md: 'flex' }}>
      <CustomLink>Privacy policy</CustomLink>
      </HStack> */}

      {/* Mobile and Tablet Screens */}
      <HStack spacing={4} alignItems="center" d={{ base: 'none', md: 'flex' }}>
        {/* <HStack alignItems="center">
          <CustomLink>Sign up</CustomLink>
          <Divider h="1rem" orientation="vertical" />
          <CustomLink>Blog</CustomLink>
          <Divider h="1rem" orientation="vertical" />
          <CustomLink>Career</CustomLink>
        </HStack>
        <HStack alignItems="center">
          <CustomLink>Documentation</CustomLink>
          <Divider h="1rem" orientation="vertical" />
          <CustomLink>Terms of use</CustomLink>
        </HStack> */}
        <CustomLink href="/privacy">Política de privacidad</CustomLink>
        <CustomLink href="/privacy">Condiciones del servicio</CustomLink>
        <CustomLink href="/privacy">Eliminación de datos de usuario</CustomLink>


      </HStack>

      {/* <Stack direction="row" spacing={5} pt={{ base: 4, md: 0 }} alignItems="center">
        {accounts.map((sc, index) => (
          <IconButton
            key={index}
            as={Link}
            isExternal
            href={sc.url}
            aria-label={sc.label}
            colorScheme={sc.type}
            icon={sc.icon}
            rounded="md"
          />
        ))}
      </Stack> */}
    </Stack>
      </Container>
    </Box>
   
  );
}

function CustomLink({ children, href }) {
  return (
    <Link href={href} fontSize="sm" _hover={{ textDecoration: 'underline' }}>
      {children}
    </Link>
  );
}

export default SmallFooter;